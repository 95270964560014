import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const NotFoundPage = () => {

  return (
    <div className="container">
      <StaticImage
				src="../assets/images/logo.jpg"
				alt=""
			/>
    </div>
  )
}

export default NotFoundPage
